import { Routes, Route, useLocation } from 'react-router-dom';

import LandingPage from './pages/LandingPage/LandingPage';
import { useEffect } from 'react';
import './styles/index.css'


const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0})
  }, [location])

  return(
    <Routes>
      <Route path='/' element={<LandingPage />} />
    </Routes>
  )
}

export default App;