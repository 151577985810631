import {ReactComponent as LogoType} from '../../assets/img/logotype_white.svg'

import './LandingPage.css'


const LandingPage: React.FC = () => {
    return(
      <div className="landing-div flex-container">
        <LogoType className="landing-logo"/>
      </div>
    )
  }
  
  
  export default LandingPage;